export const quotesArray = [
	{
		quote:
			"Jika kita mendengarkan intelektualitas kita, kita tidak akan pernah memiliki kisah cinta. Kita tidak akan pernah memiliki persahabatan. Kita tidak akan pernah berbisnis karena kita akan menjadi sinis: 'Ini akan salah.' Atau 'Dia akan menyakitiku.' Atau, 'Saya pernah mengalami beberapa kisah cinta yang buruk, jadi ...' Nah, itu omong kosong. Anda akan melewatkan hidup. Anda harus melompat dari tebing setiap saat dan membangun sayap Anda dalam perjalanan turun.",
		author: 'Ray Bradbury'
	},
	{
		quote:
			"Esok, dan esok, dan esok, merayap dengan langkah kecil ini dari hari ke hari, Hingga suku kata terakhir waktu tercatat; Dan semua kemarin kita telah menerangi para bodoh Cara menuju kematian berdebu. Keluar, keluar, lilin singkat! Hidup hanya bayangan berjalan, seorang pemain miskin, Yang bertindak dan mengeluh selama satu jam di atas panggung, Dan kemudian tidak terdengar lagi. Itu adalah sebuah cerita Dikisahkan oleh seorang idiot, penuh dengan suara dan kemarahan, Tidak berarti apa-apa.",
		author: 'William Shakespeare'
	},
	{
		quote:
			"Jika seorang pria dipanggil untuk menjadi penyapu jalan, dia harus menyapu jalan bahkan seperti Michaelangelo melukis, atau Beethoven menciptakan musik atau Shakespeare menulis puisi. Dia harus menyapu jalan dengan baik sehingga semua tuan surga dan bumi akan berhenti sejenak untuk mengatakan, 'Di sini tinggal seorang penyapu jalan hebat yang melakukan pekerjaannya dengan baik.",
		author: 'Martin Luther King'
	},
	{
		quote:
			'Memiliki kisah kita bisa sulit tetapi tidak begitu sulit seperti menghabiskan hidup kita untuk lari dari itu. Memeluk kerentanan kita berisiko tetapi tidak begitu berbahaya seperti menyerah pada cinta dan keanggotaan dan kebahagiaan - pengalaman yang membuat kita paling rentan. Hanya ketika kita cukup berani untuk menjelajahi kegelapan kita akan menemukan kekuatan tak terbatas dari cahaya kita.',
		author: 'Brene Brown'
	},
	{
		quote:
			'Setiap hari, berpikirlah saat Anda bangun, hari ini saya beruntung masih hidup, saya memiliki kehidupan manusia yang berharga, saya tidak akan menyia-nyiakannya. Saya akan menggunakan semua energi saya untuk mengembangkan diri saya, untuk memperluas hati saya kepada orang lain; untuk mencapai pencerahan untuk kebaikan semua makhluk. Saya akan berpikir baik terhadap orang lain, saya tidak akan marah atau berpikir buruk tentang orang lain. Saya akan memberikan manfaat kepada orang lain sebanyak yang saya bisa.',
		author: 'Dalai Lama'
	},
	{
		quote:
			"Hidup terlalu singkat untuk menyia-nyiakan waktu berpikir tentang apa yang dipikirkan orang lain tentang Anda. Pertama-tama, jika mereka memiliki hal-hal yang lebih baik dalam hidup mereka, mereka tidak akan memiliki waktu untuk duduk-duduk dan membicarakan Anda. Yang penting bagi saya bukanlah pendapat orang lain tentang saya, tetapi yang penting bagi saya adalah pendapat saya tentang diri saya sendiri.",
		author: 'C. JoyBell C'
	},
	{
		quote:
			"Jika Anda dapat tetap tenang saat semua orang di sekitar Anda Kehilangan akal mereka dan menyalahkan Anda, Jika Anda bisa percaya pada diri Anda saat semua orang meragukan Anda, Tetapi membuat pengurangan untuk keraguan mereka juga; Jika Anda dapat menunggu dan tidak lelah menunggu, Atau dibohongi, jangan berurusan dengan kebohongan, Atau dibenci, jangan memberi jalan pada kebencian, Dan belum juga terlihat terlalu baik, atau berbicara terlalu bijaksana",
		author: 'Rudyard Kipling'
	},
	{
		quote:
			'Ada perkelahian di dalam diriku," kata seorang tua kepada anaknya. "Ini adalah pertarungan mengerikan antara dua serigala. Seekor serigala jahat. Dia adalah kemarahan, iri, kesedihan, penyesalan, keserakahan, keangkuhan, kasihan diri, rasa bersalah, dendam, inferioritas, kebohongan, kebanggaan palsu, superioritas, dan ego. Serigala lainnya baik. dia adalah sukacita, perdamaian, cinta, harapan, ketenangan, kerendahan hati, kebaikan, kebaikan, empati, kemurahan hati, kebenaran, belas kasihan, dan iman. Pertarungan yang sama sedang terjadi di dalam dirimu.',
		author: 'Wendy Mass'
	},
	{
		quote:
			'Apapun yang Anda lakukan, Anda memerlukan keberanian. Apapun pilihan yang Anda ambil, selalu ada seseorang yang memberi tahu Anda bahwa Anda salah. Selalu ada kesulitan yang muncul yang membujuk Anda untuk percaya bahwa kritik Anda benar. Untuk memetakan suatu tindakan dan mengikutinya sampai akhir memerlukan beberapa keberanian yang sama dengan yang dibutuhkan seorang prajurit. Perdamaian memiliki kemenangannya, tetapi diperlukan pria dan wanita berani untuk memenangkannya.',
		author: 'Ralph Waldo Emerson'
	},
	{
		quote:
			'Selama manusia berada di bumi ini, bulan telah menjadi misteri bagi kita. Pikirkanlah. Dia cukup kuat untuk menarik lautan, dan ketika dia pergi, dia selalu kembali lagi. Ibuku dulu memberitahu saya bahwa Nyonya kita tinggal di bulan dan bahwa saya harus menari ketika wajahnya bersinar cerah dan tidur musim dingin ketika itu gelap.',
		author: 'Sue Monk Kidd'
	},
	{
		quote:
			"Setiap bentuk memiliki artinya sendiri. Setiap orang menciptakan arti, bentuk, dan tujuan mereka sendiri. Mengapa begitu penting - apa yang orang lain lakukan? Mengapa menjadi suci dengan fakta sederhana bahwa itu bukan milik Anda sendiri? Mengapa siapapun dan semua orang benar - asalkan bukan diri Anda sendiri? Mengapa jumlah orang lain itu menggantikan kebenaran? Mengapa kebenaran dijadikan sebagai sekadar persoalan aritmatika - dan hanya penambahan? Mengapa segalanya diputar keluar dari semua rasa untuk sesuai dengan yang lain? Harus ada alasan. Saya tidak tahu. Saya tidak pernah tahu. Saya ingin memahaminya.",
		author: 'Ayn Rand'
	},
	{
		quote:
			"Apa yang saya suka tentang memasak adalah bahwa, selama Anda mengikuti resep dengan tepat, semuanya selalu berakhir sempurna. Sayangnya tidak ada resep untuk kebahagiaan. Kebahagiaan lebih seperti pastry - yang artinya Anda dapat berusaha keras untuk tetap tenang dan tidak terlalu bekerja keras, tetapi jika Anda tidak memiliki sentuhan ringan tertentu, usaha terbaik Anda tetap sia-sia. Cara mengatasinya adalah dengan membeli apa yang Anda butuhkan. Saya berbicara tentang pastry, bukan kebahagiaan, meskipun uang memudahkan segala hal.",
		author: 'Josh Lanyon'
	},
	{
		quote:
			"Terkadang saya merasa seperti saya tidak punya tempat di mana pun, & itu akan memakan waktu lama bagi saya untuk sampai ke suatu tempat, Terkadang saya merasa sangat berat hati, tapi saya tidak bisa menjelaskannya karena saya sangat dijaga. Tapi itu adalah jalan yang sepi untuk bepergian, dan beban yang berat untuk dibawa. Dan itu adalah jalan yang panjang, panjang menuju surga tetapi saya harus sampai di sana Bisakah Anda mengirimkan seorang malaikat? Bisakah kamu mengirimkan aku malaikat... untuk membimbingku.",
		author: 'Alicia Keys'
	},
	{
		quote:
			'Sebagai manusia adalah bagian dari keseluruhan yang disebut oleh kita alam semesta, bagian yang terbatas dalam waktu dan ruang. Dia merasakan dirinya, pikirannya, dan perasaannya sebagai sesuatu yang terpisah dari yang lain, semacam ilusi optik kesadarannya. Ilusi ini adalah semacam penjara bagi kita, membatasi kita pada keinginan pribadi kita dan pada kasih sayang untuk beberapa orang terdekat kita. Tugas kita harus membebaskan diri kita dari penjara ini dengan memperluas lingkaran kasih sayang kita untuk memeluk semua makhluk hidup dan seluruh alam semesta dalam keindahannya.',
		author: 'Albert Einstein'
	},
	{
		quote:
			'Hal penting bukanlah untuk berhenti bertanya. Rasa ingin tahu memiliki alasan keberadaannya sendiri. Seseorang tidak bisa tidak kagum ketika dia memikirkan misteri keabadian, kehidupan, struktur yang mengagumkan dari kenyataan. Sudah cukup jika seseorang mencoba untuk memahami sedikit dari misteri ini setiap hari.',
		author: 'Albert Einstein'
	},
	{
		quote:
			"Rasa sakit adalah bagian yang menjengkelkan dari menjadi manusia, saya telah belajar itu terasa seperti tusukan di jantung, sesuatu yang saya harap kita semua bisa lakukan tanpanya, dalam hidup kita di sini. Rasa sakit adalah luka tiba-tiba yang tidak bisa dihindari. Tapi kemudian saya juga belajar bahwa karena rasa sakit, saya bisa merasakan keindahan, kelembutan, dan kebebasan penyembuhan. Rasa sakit terasa seperti luka tusuk cepat di jantung. Tapi kemudian penyembuhan terasa seperti angin melawan wajah Anda ketika Anda menyebarkan sayap Anda dan terbang melalui udara!",
		author: 'C. JoyBell C'
	},
	{
		quote:
			'Semua yang kita alami adalah hasil dari apa yang kita pikirkan: itu didasarkan pada pemikiran kita dan terdiri dari pemikiran kita. Jika seseorang berbicara atau bertindak dengan pikiran jahat, penderitaan akan mengikutinya seperti roda mengikuti kuku binatang yang menarik kereta .... Jika seseorang berbicara atau bertindak dengan pikiran baik, kebahagiaan akan mengikutinya seperti bayangan yang tidak pernah meninggalkannya.',
		author: 'Gautama Buddha'
	},
	{
		quote:
			'Manusia sering menjadi apa yang dia yakini dirinya. Jika saya terus berkata kepada diri saya bahwa saya tidak dapat melakukan suatu hal tertentu, mungkin saja akhirnya saya benar-benar menjadi tidak mampu melakukannya. Sebaliknya, jika saya memiliki keyakinan bahwa saya bisa melakukannya, saya pasti akan memperoleh kemampuan untuk melakukannya meskipun mungkin tidak memiliki pada awalnya.',
		author: 'Mahatma Gandhi'
	},
	{
		quote:
			'Saya berkata pada jiwaku, diam dan tunggu tanpa harapan, karena harapan akan menjadi harapan yang salah; tunggu tanpa cinta, karena cinta akan menjadi cinta yang salah; masih ada iman, tetapi iman dan cinta semuanya dalam menunggu. Tunggu tanpa pikiran, karena Anda tidak siap untuk berpikir: Jadi kegelapan akan menjadi cahaya, dan keheningan itu tarian.',
		author: 'T.S. Eliot'
	}
]

export const random = array => array[Math.floor(Math.random() * array.length)]
export const allowedKeys = [
	'q',
	'w',
	'e',
	'r',
	't',
	'y',
	'u',
	'i',
	'o',
	'p',
	'a',
	's',
	'd',
	'f',
	'g',
	'h',
	'j',
	'k',
	'l',
	'z',
	'x',
	'c',
	'v',
	'b',
	'n',
	'm',
	'Q',
	'W',
	'E',
	'R',
	'T',
	'Y',
	'U',
	'I',
	'O',
	'P',
	'A',
	'S',
	'D',
	'F',
	'G',
	'H',
	'J',
	'K',
	'L',
	'Z',
	'X',
	'C',
	'V',
	'B',
	'N',
	'M',
	';',
	"'",
	',',
	'.'
]
